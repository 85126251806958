/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import standardFormModule from '../common/standard-form-module';
import localNetworkProfile from '../../components/session/local-network-profile';
import externalAdUnitComponent from '../common/directives/external-ad-unit-component';
import {adnFormHelper} from "../common/controller/form-helper";
import {adUnitCommons, resetDimensionsBasedOnAdUnitOption} from "./ad-unit-commons";
import adUnitSizeModal from "./ad-unit-size-modal.html";
import {OBJECT_TYPE} from "../common/constants/object-type";
import {LocalPausing} from "../../components/session/local-pausing";
import {LocalNetworkInfo} from "../../components/session/local-network-info";
import {getCountryName} from "../common/directives/country-select/countries";

const MODULE_NAME = 'ad-unit-controller';

angular.module(MODULE_NAME, [standardFormModule, localNetworkProfile, externalAdUnitComponent])

  .controller('AdUnitCtrl', function($uibModal, model, modelComms, localUserProfile, Site, Blocklist, Ruleset, LocalNetworkProfile, timezonesResource, LocalUserPermissions) {
    const ctrl = this,
      commons = adUnitCommons(ctrl, timezonesResource, LocalNetworkProfile);

    ctrl.networkTimeZone = LocalNetworkProfile.get('timeZone');
    ctrl.isAxPublisher = LocalUserPermissions.getAnyVisibility().AX_PUBLISHER === true;
    ctrl.canPauseAdUnits = LocalPausing.obtain().get();
    ctrl.pureMarketplacePlatform = LocalNetworkProfile.isPureMarketplacePlatform();

    const isSpecialUser = localUserProfile.get("username") === "adnuntius@adnuntius.com" || localUserProfile.get("username") === "broker1@adnuntius.com";
    ctrl.showFullPageOptions = ctrl.pureMarketplacePlatform && isSpecialUser;

    ctrl.showCountries = LocalNetworkInfo.obtain().getInfo("siteCountries").length > 1;

    let prevSiteId;
    let afterInitAfterSave = function() {
      ctrl.model.countryExpanded = getCountryName(ctrl.model.country);
      if (prevSiteId !== _.get(ctrl.model, ['site', 'id'])) {
        Site.get({id: ctrl.model.site.id}, function(site) {
          ctrl.model.earningsAccount = site.earningsAccount;
          modelComms.relDataUpdated(ctrl.model.earningsAccount, OBJECT_TYPE.EarningsAccount);
        });
        prevSiteId = ctrl.model.site.id;
      }

      ctrl.model.targetingControl = ctrl.model.targetingControl || 'NONE';
      if (ctrl.initExtAdUnits) {
        ctrl.initExtAdUnits();
      }
      ctrl.model.dimensions = _.isArray(ctrl.model.dimensions) ? ctrl.model.dimensions : [[]];
      ctrl.model.dimensions[0] = _.isArray(ctrl.model.dimensions[0]) ? ctrl.model.dimensions[0] : [];
      ctrl.model.renderOption = ctrl.model.renderOption || null;

      const isFixed = _.get(ctrl.model, ['dimensions', 0, 0]) >= 0 && _.get(ctrl.model, ['dimensions', 0, 1]) >= 0;
      ctrl.model.adUnitOption = ctrl.model.adUnitOption || (isFixed ? 'FIXED' : 'RANGE');
      ctrl.paused = model.paused;

      ctrl.specialDimensions = !!(ctrl.model.fullPageWidth || ctrl.model.fullPageHeight || ctrl.model.mobileFullPageWidth || ctrl.model.mobileFullPageHeight);
      if (ctrl.model.fullPageWidth === 0) {
        ctrl.model.fullPageWidth = undefined;
      }
      if (ctrl.model.fullPageHeight === 0) {
        ctrl.model.fullPageHeight = undefined;
      }
      if (ctrl.model.mobileFullPageWidth === 0) {
        ctrl.model.mobileFullPageWidth = undefined;
      }
      if (ctrl.model.mobileFullPageHeight === 0) {
        ctrl.model.mobileFullPageHeight = undefined;
      }
    };

    adnFormHelper.setUpForm(ctrl, model, {
      modelComms: modelComms,
      afterInit: function() {
        afterInitAfterSave();
        commons.setTimezones();
      },
      afterSave: function() {
        afterInitAfterSave();
      }
    });

    Blocklist.query({minimal: true}, function(page) {
      ctrl.blocklists = page.results;

      if (ctrl.model.blocklist && ctrl.model.blocklist.id) {
        ctrl.model.blocklist = _.find(ctrl.blocklists, ['id', ctrl.model.blocklist.id]);
      }
    });

    if (ctrl.pureMarketplacePlatform) {
      Ruleset.query({minimal: false}, function(page) {
        ctrl.siteRulesets = _.filter(page.results, function(rs) {
          return rs.type === 'INVENTORY';
        });
        ctrl.targetingRulesets = _.filter(page.results, function(rs) {
          return rs.type === 'TARGETING';
        });
        ctrl.sizeRulesets = _.filter(page.results, function(rs) {
          return rs.type === 'SIZE';
        });
        if (ctrl.model.siteRuleset && ctrl.model.siteRuleset.id) {
          ctrl.model.siteRuleset = _.find(page.results, ['id', ctrl.model.siteRuleset.id]);
        }
        ctrl.rulesets = page.results;
      });
    }

    ctrl.removeDimensions = function(index) {
      _.pullAt(ctrl.model.dimensions, index);
      ctrl.eventHook.removeControlHook('dims' + index + '.width');
      ctrl.eventHook.removeControlHook('dims' + index + '.height');
      ctrl.editsMade();
    };
    ctrl.addDimensions = function() {
      ctrl.model.dimensions = ctrl.model.dimensions || [];
      ctrl.model.dimensions.push([]);
      ctrl.editsMade();
    };
    ctrl.eventHook = {};

    ctrl.previewSize = function() {
      $uibModal.open({
        template: adUnitSizeModal,
        controllerAs: 'modalCtrl',
        size: 'lg',
        controller: function() {
          const modalCtrl = this;

          modalCtrl.minWidth = _.isFinite(ctrl.model.minWidth) ? ctrl.model.minWidth : 0;
          modalCtrl.minHeight = _.isFinite(ctrl.model.minHeight) ? ctrl.model.minHeight : 0;
          modalCtrl.width = _.isFinite(ctrl.model.width) ? ctrl.model.width : 0;
          modalCtrl.height = _.isFinite(ctrl.model.height) ? ctrl.model.height : 0;

          modalCtrl.containerWidth = Math.max(modalCtrl.minWidth, modalCtrl.width) + 15;
          modalCtrl.containerHeight = Math.max(modalCtrl.minHeight, modalCtrl.height) + 3;
        }
      });
    };

    commons.setTargetingTypes();
    commons.setTimezoneChange();

    ctrl.onBeforeSubmit = function() {
      ctrl.model.timeZone = commons.getBeforeSubmitTimeZoneValue();
      resetDimensionsBasedOnAdUnitOption(ctrl.model);

      if (!ctrl.specialDimensions) {
        ctrl.model.fullPageWidth = 0;
        ctrl.model.fullPageHeight = 0;
        ctrl.model.mobileFullPageWidth = 0;
        ctrl.model.mobileFullPageHeight = 0;
      }

      if (ctrl.beforeSubmitExtAdUnit) {
        ctrl.beforeSubmitExtAdUnit();
      }
    };
  });

export default MODULE_NAME;