/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import standardAnalysisModule from '../common/standard-analysis-module';
import {AdServerConfig} from "../../components/api/api";
import explorationComponent from '../common/directives/exploration-component';
import creativeTotalsPreview from "./creative-totals-preview.html";
import creativeDirectPreview from "../creative/ad/creative-direct-preview";
import {LocalCreativePreview} from "../../components/session/local-creative-preview-data";
import {Uuid} from "../../components/util/uuid";
import {statComparator} from "../common/controller/comparators";
import {AdTagReplacers} from "./ad-tag-replacers";
import {calculateNetCpm} from "../line-item/fees-calculator";

const MODULE_NAME = "ad-unit-analysis";

angular.module(MODULE_NAME, [standardAnalysisModule, explorationComponent, creativeDirectPreview])

  .controller('AdUnitAnalysisCtrl', function ($stateParams, LocalUserPermissions, AdUnitTag, Creative, diagRequestResource, LocalNetworkProfile, LineItem, Site, EarningsAccount, Ruleset, $uibModal, model, modelComms, tags) {
    const ctrl = this;

    ctrl.networkTagId = LocalNetworkProfile.get('tagId');
    const ogEmail = "<a href=\"https://delivery.adnuntius.com/c?network=[[TAG_ID]]&auId=[[AD_UNIT]]&autor=true&iid=[UNIQUE_IDENTIFIER]\">\n    <img src=\"https://delivery.adnuntius.com/i?network=[[TAG_ID]]&auId=[[AD_UNIT]]&format=image&iid=[UNIQUE_IDENTIFIER]\"[[AD_UNIT_WIDTH]][[AD_UNIT_HEIGHT]] />\n</a>\n";
    ctrl.idType = "CUSTOM";
    ctrl.defaultMailchimp = "*|CAMPAIGN_UID|**|UNIQID|*";
    const ogVast = AdServerConfig.obtain().getUri("i") + "?network=[[TAG_ID]]&auId=[[AD_UNIT]]&tt=[[VAST_VERSION]]";
    const ogNative = AdServerConfig.obtain().getUri("i") + "?network=[[TAG_ID]]&auId=[[AD_UNIT]]&tt=native";

    ctrl.chartCurrency = _.get(model, ['floorPrice', 'currency']);

    const updateTagCode = function() {
      ctrl.emailCode = "";

      const auTagId = _.get(ctrl, 'adUnit.tags.auId');
      let adUnitString = ogEmail.replace(/\[\[TAG_ID]]/g, ctrl.networkTagId).replace(/\[\[AD_UNIT]]/g, auTagId);
      const widthReplacer = model.width ? (" width=\"" + model.width + "\"") : "";
      const heightReplacer = model.height ? (" height=\"" + model.height + "\"") : "";
      adUnitString = adUnitString.replace("[[AD_UNIT_WIDTH]]", widthReplacer).replace("[[AD_UNIT_HEIGHT]]", heightReplacer);

      if (ctrl.idType === 'MAILCHIMP') {
        adUnitString = adUnitString.replace(/\[UNIQUE_IDENTIFIER]/g, ctrl.defaultMailchimp);
      } else if (ctrl.uniqueId) {
        adUnitString = adUnitString.replace(/\[UNIQUE_IDENTIFIER]/g, ctrl.uniqueId);
      }
      ctrl.emailCode = ctrl.emailCode + adUnitString;

      ctrl.vast2 = ogVast.replace(/\[\[TAG_ID]]/g, ctrl.networkTagId).replace(/\[\[AD_UNIT]]/g, auTagId).replace(/\[\[VAST_VERSION]]/g, "vast2");
      ctrl.vast3 = ogVast.replace(/\[\[TAG_ID]]/g, ctrl.networkTagId).replace(/\[\[AD_UNIT]]/g, auTagId).replace(/\[\[VAST_VERSION]]/g, "vast3");
      ctrl.vast4 = ogVast.replace(/\[\[TAG_ID]]/g, ctrl.networkTagId).replace(/\[\[AD_UNIT]]/g, auTagId).replace(/\[\[VAST_VERSION]]/g, "vast4");
      ctrl.vast4_1 = ogVast.replace(/\[\[TAG_ID]]/g, ctrl.networkTagId).replace(/\[\[AD_UNIT]]/g, auTagId).replace(/\[\[VAST_VERSION]]/g, "vast4-1");
      ctrl.vast4_2 = ogVast.replace(/\[\[TAG_ID]]/g, ctrl.networkTagId).replace(/\[\[AD_UNIT]]/g, auTagId).replace(/\[\[VAST_VERSION]]/g, "vast4-2");

      ctrl.native = ogNative.replace(/\[\[TAG_ID]]/g, ctrl.networkTagId).replace(/\[\[AD_UNIT]]/g, auTagId);

      if (ctrl.whitelabel && ctrl.whitelabelDomain) {
        ctrl.emailCode = AdTagReplacers.replace(ctrl.emailCode, ctrl.whitelabelDomain, ctrl.networkTagId);
        ctrl.vast2 = AdTagReplacers.replace(ctrl.vast2, ctrl.whitelabelDomain, ctrl.networkTagId);
        ctrl.vast3 = AdTagReplacers.replace(ctrl.vast3, ctrl.whitelabelDomain, ctrl.networkTagId);
        ctrl.vast4 = AdTagReplacers.replace(ctrl.vast4, ctrl.whitelabelDomain, ctrl.networkTagId);
        ctrl.vast4_1 = AdTagReplacers.replace(ctrl.vast4_1, ctrl.whitelabelDomain, ctrl.networkTagId);
        ctrl.vast4_2 = AdTagReplacers.replace(ctrl.vast4_2, ctrl.whitelabelDomain, ctrl.networkTagId);
        ctrl.native = AdTagReplacers.replace(ctrl.native, ctrl.whitelabelDomain, ctrl.networkTagId);
      }
    };

    ctrl.changeValue = function() {
      updateTagCode();
    };

    ctrl.isNew = $stateParams.isNew;

    ctrl.canSeeReports = LocalUserPermissions.getAnyVisibility().RUN_REPORTS === true
      || LocalUserPermissions.getAnyVisibility().AX_PUBLISHER === true;
    ctrl.isPureMarketplace = LocalNetworkProfile.isPureMarketplacePlatform();

    let updateSelTags = function() {
      ctrl.selTag = {name: 'COM'};
      _.forEach(model.tags.tags, function(tag) {
        if (tag.name === "COM") {
          tag.order = 1;
          return;
        }
        tag.order = 2;
      });
    };
    model.tags = tags;
    updateSelTags();
    ctrl.adUnit = model;

    if (_.get(ctrl.adUnit, ['tags', 'tags'])) {
      ctrl.adUnit.tags.tags.push({name: "email"});
      ctrl.adUnit.tags.tags.push({name: "vast"});
      ctrl.adUnit.tags.tags.push({name: "native"});
    }

    modelComms.addSubscriber(function(data, type) {
      if (_.get(data, 'name') && !type) {
        if (ctrl.isNew) {
          ctrl.isNew = false;
          AdUnitTag.get({id: data.id}).$promise.then(function(tags) {
            ctrl.whitelabelDomain = AdTagReplacers.getNetworkWhitelabelDomain(LocalNetworkProfile.getNetworkTag());
            ctrl.whitelabel = !!ctrl.whitelabelDomain;
            model.tags = tags;
            updateSelTags();
            ctrl.adUnit.tags = model.tags || {};
            ctrl.adUnit.tags.tags = ctrl.adUnit.tags.tags || [];
            ctrl.adUnit.tags.tags.push({name: "email"});
            ctrl.adUnit.tags.tags.push({name: "vast"});
            ctrl.adUnit.tags.tags.push({name: "native"});
            updateTagCode();
          });
        } else {
          ctrl.adUnit = data;
          ctrl.adUnit.tags = model.tags;
          ctrl.whitelabelDomain = AdTagReplacers.getNetworkWhitelabelDomain(LocalNetworkProfile.getNetworkTag());
          ctrl.whitelabel = !!ctrl.whitelabelDomain;
          updateTagCode();
        }
      }
    });

    ctrl.diag = {auId: tags.auId, tzo: new Date().getTimezoneOffset(), kv: [{k: '', v: ''}]};

    ctrl.addKv = function() {
      ctrl.diag.kv.push({k: '', v: ''});
    };

    ctrl.removeKv = function(index) {
      _.pullAt(ctrl.diag.kv, index);
    };

    let totalsData;
    let creativeSorting;
    ctrl.statsHook = function(totals, sorting) {
      if (totals) {
        totalsData = _.cloneDeep(totals);
      }
      creativeSorting = _.cloneDeep(sorting);
    };

    ctrl.callbackHook = function() {
      ctrl.doPreviews();
    };

    ctrl.doPreviews = function() {
      $uibModal.open({
        template: creativeTotalsPreview,
        controllerAs: 'modalCtrl',
        size: 'lg',
        controller: /*@ngInject*/ function() {
          const modalCtrl = this;

          modalCtrl.spinner = true;
          const creativeIds = _.map(totalsData, function(datum) {
            return datum.creative.id;
          });
          const keyedTotalsData = _.keyBy(totalsData);
          Creative.info({id: creativeIds.join(";"), minimal: true}).$promise.then(function(data) {
            modalCtrl.creativeInfo = data.results;
            _.forEach(modalCtrl.creativeInfo, function(c) {
              c.creative.allowScript = false;
              c.creative.uuid = Uuid.generate();
              if (keyedTotalsData[c.id]) {
                _.assign(c, keyedTotalsData[c.id]);
              }
              c.redrawHook = {};
            });
            modalCtrl.spinner = false;
          }).catch(function() {
            modalCtrl.spinner = false;
          });

          modalCtrl.preview = function(c) {
            c.redrawHook.triggerPreview(c);
          };

          modalCtrl.sortComparator = statComparator;
          modalCtrl.sorting = creativeSorting;
          modalCtrl.max = 10;
          modalCtrl.step = 10;

          modalCtrl.setCookieInfo = function(creative) {
            const previewData = {
              networkId: LocalNetworkProfile.getNetworkTag(),
              creativeId: creative.id,
              creativeWidth: creative.width,
              creativeHeight: creative.height
            };
            new LocalCreativePreview().add(creative.uuid, LocalNetworkProfile.getNetworkTag(), previewData);
          };
        }
      });
    };

    ctrl.runDiag = function() {
      ctrl.diagRunning = true;
      ctrl.diagResults = false;
      ctrl.error = null;
      ctrl.errorInfo = null;

      // Check the floor price every time, in case it has been changed
      ctrl.floorPrice = null;
      if (ctrl.adUnit.floorPrice.amount) {
        ctrl.floorPrice = ctrl.adUnit.floorPrice;
      } else if (ctrl.adUnit.siteRuleset && ctrl.adUnit.siteRuleset.id) {
        Ruleset.get(ctrl.adUnit.siteRuleset, function (siteRuleset) {
          ctrl.floorPrice = siteRuleset.floorPrice;
        });
      } else if (ctrl.adUnit.site && ctrl.adUnit.site.id) {
        Site.get(ctrl.adUnit.site, function (site) {
          if (site.siteRuleset && site.siteRuleset.id) {
            Ruleset.get(site.siteRuleset, function (siteRuleset) {
              ctrl.floorPrice = siteRuleset.floorPrice;
            });
          } else if (site.earningsAccount && site.earningsAccount.id) {
            EarningsAccount.get(site.earningsAccount, function (earningsAccount) {
              if (earningsAccount.siteRuleset && earningsAccount.siteRuleset.id) {
                Ruleset.get(earningsAccount.siteRuleset, function (siteRuleset) {
                  ctrl.floorPrice = siteRuleset.floorPrice;
                });
              }
            });
          }
        });
      }

      diagRequestResource.dx(ctrl.diag).then(function(data) {
        let normaliseKeys = ['unmatchedAds', 'matchedAds', 'winningAds', 'competingExternalAds', 'lossReasons'];
        let dropSquares = ['matchedExpressions', 'unmatchedExpressions'];
        _.forEach(data.lineItemParticipants, function(l) {
          _.forEach(normaliseKeys, function(k) {
            l[k] = l[k].replace("{", "").replace("}", "").replace("[", "").replace("]", "");
            l[k + "Count"] = l[k].length > 0 ? (l[k].match(/,/g) || []).length + 1 : 0;
          });
          if (l.lossReasons) {
            l.lossReasons = l.lossReasons.split(",").map(entry => _.trim(entry));
            l.lossReasonsCount = l.lossReasons.length;
          } else {
            l.lossReasons = [];
            l.lossReasonsCount = 0;
          }
          _.forEach(dropSquares, function(k) {
            l[k] = l[k].replace("[", "").replace("]", "");
          });
        });

        ctrl.diagRunning = false;
        ctrl.diagResults = true;

        ctrl.entryCost = "Unknown";
        let entryCostArray = data.entryCost.split(" ");
        if (entryCostArray.length === 2) {
          let entryCostValue = Number(entryCostArray[1]);
          let entryCostCurrency = (entryCostValue === 0) ? "" : " " + entryCostArray[0];
          ctrl.entryCost = "" + entryCostValue + entryCostCurrency;
        }

        let doQueries = function(key, max, external) {
          let newKey = external ? 'competing' : key;
          ctrl[newKey + 'Results'] = false;
          ctrl[newKey] = _.filter(data.lineItemParticipants, function(l) {
            let matchedStage = l.reachedStage === key.toUpperCase();
            if (matchedStage && key.toUpperCase() !== 'SELECTED') {
              return matchedStage;
            }
            if (external) {
              return matchedStage && l.competingExternalAdsCount > 0;
            } else {
              return matchedStage && l.competingExternalAdsCount < 1;
            }
          });
          let ids = _.map(ctrl[newKey], 'externalId');
          LineItem.query({id: ids.slice(0, max).join(';')}).$promise.then(function(data) {
            let lineItems = data.results;
            _.forEach(ctrl[newKey], function(l) {
              let li = _.find(lineItems, ['id', l.externalId]);
              if (li) {
                l.lineItemName = _.get(li, 'name');
                l.type = li.type;
                if (li.type === 'SPONSORSHIP') {
                  l.sponsorshipPercentage = li.sponsorshipPercentage;
                } else {
                  l.smoothed = li.smoothed;
                }
                l.tier = li.tier;
                l.bidSpecification = li.bidSpecification;
                l.objectives = li.objectives;
                if (l.bidSpecification.cpm) {
                  const fees = (l.bidSpecification.cpmFees || []);
                  if (l.bidSpecification.cpmTechFee) {
                    fees.push(l.bidSpecification.cpmTechFee);
                  }
                  l.bidSpecification.cpm = calculateNetCpm(fees, l.bidSpecification.cpm);
                }

              }
            });
            ctrl[newKey + 'Results'] = true;
          });
        };

        doQueries('winning', 30);
        doQueries('selected', 30);
        doQueries('selected', 30, true);
        doQueries('eligible', 30);
        doQueries('unsuitable', 30);
        doQueries('ineligible', 10);

        const unsuitable = ctrl.unsuitable || [];
        const ineligible = ctrl.ineligible || [];
        ctrl.ineligible = ineligible.concat(unsuitable);

        ctrl.totalLineItems = data.lineItemParticipants.length;
      }).catch(function(response) {
        ctrl.errorInfo = response.info;
        ctrl.error = response.clientError || response.statusText || "";
        ctrl.diagRunning = false;
      });
    };
  });

export default MODULE_NAME;