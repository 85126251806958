/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import uiTranslate from 'angular-translate';

import resources from '../../../components/api/resources/resources';
import previewComponent from './preview-component';
import promiseSpinner from '../../common/directives/html/promise-spinner-component';
import {doPreview} from "../../base/nude/preview-module";

const MODULE_NAME = 'creative-direct-component';

angular.module(MODULE_NAME, [
  uiTranslate,
  resources,
  promiseSpinner,
  previewComponent
])

  .component('adnCreativeDirect', {
    bindings: {
      creative: '<'
    },
    template: `<adn-promise-spinner resolved="ctrl.resolved"><div id="target-{{ctrl.creative.id || ctrl.creative.creativeId}}"></div></adn-promise-spinner>`,
    controllerAs: 'ctrl',
    controller: function($element, $document, $window, $timeout, $scope, CreativePreview, LocalNetworkProfile) {
      const ctrl = this;

      ctrl.$onInit = function() {
        const previewData = {
          networkId: LocalNetworkProfile.getNetworkTag(),
          creativeId: ctrl.creative.id || ctrl.creative.creativeId,
          creativeWidth: ctrl.creative.width || ctrl.creative.creativeWidth,
          creativeHeight: ctrl.creative.height || ctrl.creative.creativeHeight,
          allowScript: ctrl.creative.allowScript,
          impressionCallback: function() {
            ctrl.resolved = true;
          }
        };

        function previewIt() {
          ctrl.resolved = false;
          doPreview($window, $document, $element, $timeout, $scope, previewData, false, CreativePreview, ctrl, "target-" + previewData.creativeId);
        }
        if (_.isObject(ctrl.creative.redrawHook)) {
          previewData.clearTarget = true;
          ctrl.creative.redrawHook.triggerPreview = function(c) {
            if (c) {
              previewData.allowScript = c.allowScript;
            }
            previewIt();
          };
        }
        previewIt();
      };
    }
  });

export default MODULE_NAME;