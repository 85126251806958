/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

import template from './ad-unit-copy-modal.html';

import {resetDimensionsBasedOnAdUnitOption} from "./ad-unit-commons";

import standardActionsModule from '../common/standard-actions-module';
import { actionsHelper } from "../common/controller/actions-helper";

const MODULE_NAME = 'ad-unit-actions-controller';

angular.module(MODULE_NAME, [standardActionsModule])

  .controller('AdUnitActionsCtrl', function($q, toastr, $stateParams, $state, $uibModal, model, modelComms, readOnly) {
    const ctrl = this;

    actionsHelper(ctrl, model, modelComms, readOnly, $stateParams, $uibModal, $q, toastr, $state, template, {
      url: 'app.ad-units.ad-unit',
      lcase: 'ad unit',
      ucase: 'Ad Unit',
      preCopy: function(copyModel) {
        resetDimensionsBasedOnAdUnitOption(copyModel);
      }
    });
  });

export default MODULE_NAME;